<!-- eslint-disable vue/no-parsing-error -->
<template>
  <section>
    <b-row>
      <b-col md="9">
        <b-card
          no-body
          class="coupon-card"
        >
          <b-card-header>
            <div
              class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
              style="gap: 8px;"
            >
              <div>
                <h4>{{ $t('Tạo phiếu kiểm kho') }}</h4>
                <!-- <div>{{ $t('Create combos with attractive offers that will attract more buyers!') }}</div> -->
              </div>
            </div>

            <!-- <div class="filter-tab w-100">
              <div
                v-for="item in filterTabs"
                :key="item.id"
                :class="['filter-tab__item', {'active': filterSelected == item.id}]"
                @click="filterSelected = item.id"
              >
                {{ $t(item.label) }}
              </div>
            </div> -->
          </b-card-header>
          <b-card-body>
            <!-- search input -->
            <div class="custom-search d-flex justify-content">
              <b-form-group style="width: 450px;">
                <div class="d-flex align-items-center mr-1">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Tìm kiếm theo mã nhâp hàng"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              class="custom-good-table-row-head"
              :group-options="{
                enabled: true,
                headerPosition: 'top'
              }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-header-row"
                slot-scope="props"
              >
                <b-avatar
                  :key="props.row.img"
                  :src="props.row.img"
                  variant="light-info"
                  rounded
                />
                <span class="my-fancy-class">
                  {{ props.row.label }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  v-if="props.column.field === 'count'"
                  class="count-w mx-auto"
                >
                  <b-form-input />
                </div>
                <div
                  v-if="props.column.field === 'value'"
                >
                  {{ props.row.value }}
                </div>
                <div
                  v-if="props.column.field === 'actualstock'"
                >
                  {{ props.row.actualstock }}
                </div>
                <div
                  v-if="props.column.field === 'inventory'"
                >
                  {{ props.row.inventory }}
                </div>
                <div
                  v-if="props.column.field === 'name'"
                  class="avatar-name mx-auto"
                >
                  <div class="name-child">
                    {{ props.row.name }}
                  </div>
                  <div class="sku">
                    SKU: {{ props.row.sku }}
                  </div>
                </div>
                <div
                  v-else-if="props.column.field === 'close'"
                  class="text-center"
                >
                  <b-button
                    variant="flat-danger"
                    class="text-danger btn-icon rounded-circle"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','30','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
        <b-card
          title="Ghi Chú"
        >
          <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          />
        </b-card>
      </b-col>
      <b-col md="3">
        <div class="position-sticky top">
          <b-card
            title="Kho Kiểm"
          >
            <b-form-select
              v-model="selected"
              :options="reasonOptions"
            />
            <b-button
              variant="primary"
              type="button"
              class="mt-2"
              to="add"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              {{ $t('Create new') }}
            </b-button>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BAvatar,
  BFormSelect,
  BPagination,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'

export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BRow,
    BCol,
    BFormTextarea,
  },
  mixins: [general],
  data() {
    return {
      columns: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Tồn kho',
          field: 'inventory',
          tdClass: 'text-center',
        },
        {
          label: 'Tồn thực thế',
          field: 'actualstock',
          tdClass: 'text-center',
        },
        {
          label: 'Số lượng',
          field: 'count',
          tdClass: 'text-center',
        },
        {
          label: 'Giá trị lệch',
          field: 'value',
          tdClass: 'text-center',
        },
        {
          label: 'Xóa',
          field: 'close',
          tdClass: 'text-center',
        },
      ],
      selected: null,
      reasonOptions: [
        {
          value: null,
          text: 'Khác',
        },
        {
          value: 'not1',
          text: 'note 1',
        },
        {
          value: 'not2',
          text: 'note 2',
        },
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '2300',
          subtitle: 'Số lượng tồn kho',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'light-info',
          title: '8.549M',
          subtitle: 'Tổng giá bán',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'light-danger',
          title: '1.423M',
          subtitle: 'Tổng giá trị',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '7.126M',
          subtitle: 'Lợi nhuận',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      filterSelected: 0,
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      supplierOptions: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-dark',
        1     : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 0) return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  created() {
  },
  methods: {
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-good-table-row-head {
  .custom-select{
    width: 100%;
  }
  .b-avatar {
    margin-right: 8px;
  }
  table.vgt-table {
    font-size: 14px !important;
  }
  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
  }
  td {
    vertical-align: middle !important;
  }
  .avatar-name {
    min-width: 200px !important;
    padding-left: 41px;

    .name-child {
      color: #333;
      font-weight: 500;
    }
  }
  .count-w {
    max-width: 100px;
  }
  .vgt-table.bordered th.vgt-row-header{
    border-top: 3px solid #ebe9f1;
    border-bottom: none;
    font-size: 14px;
    thead th {
      vertical-align: middle;
      text-align: center;
      padding-right: 0.75em;
      white-space: nowrap;
    }
    td {
      vertical-align: middle;
    }

    .custom-th-class {
      text-align: end;
    }
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
